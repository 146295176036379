<template>
  <form class="w-64 ml-auto mr-auto grid gap-4" @submit.prevent="submit">
    <FormAlert :type="alertType" :message="alertMessage" />

    <FormInput
      ref="emailinput"
      :modelValue="email"
      type="email"
      required
      placeholder="Email"
      @update:modelValue="emit('update:email', $event)"
    />

    <input
      type="submit"
      class="cursor-pointer py-3 px-4 text-white article-label !font-medium w-full"
      :value="$t('sso.LoginWithSSO')"
      :class="
        isMm()
          ? 'rounded-md bg-black transform translate-y-px'
          : 'rounded-full bg-blue hover:bg-denimblue'
      "
    />
  </form>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    email: string
    returnToFrontPageAfterLogin?: boolean
  }>(),
  {
    returnToFrontPageAfterLogin: false,
  }
)

const nuxtApp = useNuxtApp()

const emit = defineEmits<{
  (e: 'update:email', value: string): void
}>()

async function submit() {
  const ssoProvider = await nuxtApp.$api.user.isSSOEmail(props.email)
  if (ssoProvider) {
    const url = new URL(ssoProvider.providerUrl)
    url.searchParams.set(
      'returnurl',
      props.returnToFrontPageAfterLogin
        ? window.location.origin
        : window.location.origin + window.location.pathname
    )
    await navigateTo(url.toString(), {
      external: true,
    })
  } else {
    setAlert('sso.NotSSOEmail')
  }
}

const { setAlert, alertType, alertMessage } = useAlert()
</script>
